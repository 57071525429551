import { ReactElement, useMemo } from 'react';
import { Outlet } from 'react-router-dom';

import { PageLoader } from '@components/Layout';

import ErrorPage from '@pages/Error';

import { useAuthSessionContext } from '@context/authSession';
import { isLearnerOrAlumni, useAuthStoreContext } from '@context/authStore';
import { useEcpEnabled } from '../../hooks/EcpEnabled';

interface ProtectedRootRouteProps {
  children?: ReactElement;
}

export const ProtectedRootRoute = ({ children }: ProtectedRootRouteProps) => {
  const { isAuthenticated, isLoading: isLoadingDiscoveryUser } = useAuthSessionContext();
  const { isLoadingUser: isLoadingGusUser, isLoadingUserPreferences, userData } = useAuthStoreContext();

  const { ecpEnabled, isLoadingData: isLoadingEcpData } = useEcpEnabled();

  const hasRoleThatIsNotLearner = userData.access.some(({ tenant }) => !isLearnerOrAlumni(tenant.permission?.role));

  let componentToRender: React.ReactElement = <PageLoader />;

  const isLoadingUserData = useMemo(
    () => isLoadingDiscoveryUser || isLoadingGusUser || isLoadingUserPreferences || isLoadingEcpData,
    [isLoadingDiscoveryUser, isLoadingGusUser, isLoadingUserPreferences, isLoadingEcpData],
  );

  if (!isLoadingUserData) {
    if (isAuthenticated && ecpEnabled) {
      componentToRender = children || <Outlet />;
    } else if (isAuthenticated && !ecpEnabled && hasRoleThatIsNotLearner) {
      componentToRender = <ErrorPage errorType='access-denied-ecp-disabled-other-users' />;
    } else {
      componentToRender = <ErrorPage errorType='route-not-found' />;
    }
  }

  return componentToRender;
};
