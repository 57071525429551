import { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import RecommendersHomePage from '@pages/RecommendersHomePage';

import { PageLoader } from '@components/Layout';
import { ROUTES } from '@constants/routes';
import { isLearnerOrAlumni, useAuthStoreContext } from '@context/authStore';

export const Root = () => {
  const navigate = useNavigate();
  const { currentAccess, isLoadingUser, isLoadingUserPreferences } = useAuthStoreContext();

  const isLearner = isLearnerOrAlumni(currentAccess.tenant?.permission?.role);

  const isLoadingData = useMemo(
    () => isLoadingUser || isLoadingUserPreferences || !currentAccess.tenant,
    [isLoadingUser, isLoadingUserPreferences, currentAccess],
  );

  useEffect(() => {
    if (isLearner) navigate(ROUTES.LANDING);
  }, [isLearner, isLoadingData]);

  if (isLoadingData) return <PageLoader />;

  if (!isLearner) {
    return <RecommendersHomePage />;
  }

  return null;
};
