import { useParams } from 'react-router-dom';

import { DISCOVERY_ADMIN_URL } from '@constants/externalRoutes';
import { BURGER_MENU_ADMIN_OPTIONS } from '@constants/menus';
import { useAuthStoreContext } from '@context/authStore';
import useIsRootAdmin from '@hooks/useIsRootAdmin';
import { AppMenuListItemProps, BrightpathHeader } from '@youscience/brightpath-header';
import { CoreBox } from '@youscience/khaleesi';

import { MenuLinks } from './MenuLinks';

import { sxStyles } from './Header.styles';
import './styles.scss';

export const Header = () => {
  const { isLoadingUser, userData, accessDocumentId, isImpersonated, setUserPreferences, getUserPreferences } =
    useAuthStoreContext();

  const isRootAdmin = useIsRootAdmin();

  const params = useParams();

  let options: AppMenuListItemProps[] = [];

  const handleTenantSelect = async (accessId: string) => {
    if (accessId !== accessDocumentId) {
      await setUserPreferences(accessId);
      // ? This is a temporary call, it helps to set x-current-access cookie
      await getUserPreferences();
    }
  };

  if (!isLoadingUser && isRootAdmin) {
    options = BURGER_MENU_ADMIN_OPTIONS;

    if (params.tenantId) {
      options = [
        ...options,
        {
          item: {
            title: {
              id: 'back-to-tenant-page',
              defaultMessage: 'Back to Tenant Page',
            },
            key: 'back-to-tenant-page',
            path: `${DISCOVERY_ADMIN_URL}/tenants/${params.tenantId}`,
            icon: <i className='ys-icon-right-row' />,
            translateKey: 'back-to-tenant-page',
          },
        },
      ];
    }
  }

  return (
    <CoreBox className='c-header' component='header' sx={sxStyles.header}>
      <BrightpathHeader
        currentProductPage='education-career-plan'
        accessDocumentId={accessDocumentId}
        productSpecificNode={<MenuLinks options={options} />}
        handleTenantSelectCallback={handleTenantSelect}
        userDocument={userData}
        impersonating={isImpersonated}
      />
    </CoreBox>
  );
};
